<template>
  <b-modal
      id="cash-flow-details-modal"
      hide-header
      hide-footer
      centered
      size="xl"
      @hidden="hidden"
    >
      <div class="header">
        <div class="header-content">
          <p>Detalhes</p>
          <div class="header-icons">
            <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getInstallments()"/>
            <Close class="close-icon" @click="closeModal" />
          </div>
        </div>
      </div>
      <div class="body">
          <InstallmentsTable 
            v-if="!loading"
            @selected-bills="selectedBills"
            :installments="installments"
            :onEdit="onEditBills"
            :getInstallments="getInstallments"
          />
          <div v-else>
            <b-skeleton />
            <b-skeleton />
            <b-skeleton />
            <b-skeleton />
          </div>
          <div class="pagination-position no-print">
            <div class="actions">
              <b-button
                v-if="selectedToPay?.length"
                @click="$bvModal.show('selected-pay-modal')"
                class="wh-button"
                variant="outline-primary"
                outline
              >
                Pagar selecionado(s)
              </b-button>
              <b-button
                v-if="selectedToReceive?.length"
                @click="$bvModal.show('selected-bills-to-receive-modal')"
                class="wh-button"
                variant="outline-primary"
                outline
              >
                Receber selecionado(s)
              </b-button>
            </div>

            <!-- <b-pagination
                size="sm"
                v-model="page"
                :total-rows="count"
                :per-page="limit"
                first-number
                last-number
            /> -->
          </div>
      </div>

      <BillsToPaySelectedPayModal
          identifier="selected-pay-modal"
          :installments="selectedToPay"
          :getBillsToPay="getInstallments"
          @onHide="onHideSelectedPayModal"
      />

      <SelectedBillsToReceiveModal
          :selectedInstallments="selectedToReceive"
          :onEdit="onEditBillsToReceive"
          :getBillsToReceive="getInstallments"
          @onHide="onHideSelectedReceiveModal"
      />

      <BillsToReceiveModal
        :billToReceiveId="billToReceiveId"
        @onHide="billToReceiveId = null"
      />
  
      <BillItemsModal
        :billId="billToReceiveId"
        @hidden="billToReceiveId = null"
      />
  
      <BillsToPayModal
        :billToPayId="billToPayId"
        @onHide="billToPayId = null"
      />
    </b-modal>
</template>
<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    props: {
      bankAccountList: Array,
      date: String,
    },
    components: {
      Loading: () => import('@/assets/icons/loading.svg'),
      Close: () => import('@/assets/icons/close.svg'), 
      InstallmentsTable: () => import('@/modules/financial/components/InstallmentsTable'),
      BillsToPaySelectedPayModal: () => import('@/modules/financial/modals/BillsToPaySelectedPayModal'),
      SelectedBillsToReceiveModal: () => import('@/modules/financial/modals/SelectedBillsToReceiveModal'),
      BillsToReceiveModal: () => import('@/modules/financial/modals/BillsToReceiveModal'),
      BillItemsModal: () => import('@/modules/financial/modals/BillItemsModal'),
      BillsToPayModal: () => import('@/modules/financial/modals/BillsToPayModal'),
    },
    data() {
      return {
        loading: false,
        clinic: getCurrentClinic(),
        installments: [],
        // page: 1,
        // limit: 0,
        // count: 0,
        // last_page: 0,

        selectedToPay: [],
        selectedToReceive: [],
        billToPayId: null,
        billToReceiveId: null,
      }
    },
    methods: {
      hidden() {
        this.installments = []
        this.$emit('hidden')
      },
      closeModal() {
        this.$bvModal.hide('cash-flow-details-modal')
      },
      getInstallments () {
        this.loading = true
        const bankAccountIds = this.bankAccountList.map(x => x.id)
        api.getCashFlowIInstallments(this.clinic.id, bankAccountIds, this.date)
        .then(res => {
            this.installments = res.data
        })
        .catch(err => this.$toast.error(err.response.data.message))
        .finally(() => (this.loading = false))
      },
      selectedBills(billsToPay, billsToReceive) {
        this.selectedToPay = billsToPay ?? []
        this.selectedToReceive = billsToReceive ?? []
      },
      onEditBills(installment) {
        if (installment?.payment?.bill?.type === 'bill_to_pay') {
          this.onEditBillsToPay(installment?.payment?.bill?.id)
        } else {
          this.onEditBillsToReceive(installment?.payment?.bill?.id, installment?.payment?.bill?.bill_items?.length)
        }
      },
      onEditBillsToReceive(billToReceiveId, hasBillItems) {
        if (this.billToReceiveId) {
          this.billToReceiveId = null
        }
        this.billToReceiveId = billToReceiveId
        this.$bvModal.show(!hasBillItems ? 'bills-to-receive-modal' : 'bill-items-modal')
      },
      onEditBillsToPay(billToPayId) {
        if (this.billToPayId) {
          this.billToPayId = null
        }
        this.billToPayId = billToPayId
        this.$bvModal.show('bill-to-pay-modal')
      },
      onHideSelectedPayModal() {
        this.selectedToPay = []
        this.getInstallments()
      },
      onHideSelectedReceiveModal() {
        this.selectedToReceive = []
        this.getInstallments()
      },  
    },
    watch: {
      date() {
        this.date && this.getInstallments()
      },
    }
}
</script>
<style lang="scss">
#cash-flow-details-modal {
    .modal-content {
      .modal-body {
        padding: 0;
  
        .header {
          border-bottom: 1px solid var(--neutral-200);
          .header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 24px;
            p {
              font-weight: 600;
              font-size: 16px;
              color: var(--type-active);
            }
            .header-icons {
              display: flex;
              align-items: center;
              gap: 10px;
            }
            .close-icon {
              fill: var(--neutral-500);
              height: 24px;
              width: 24px;
            }
          }
        }
        .body {
          padding: 20px;
          .end-footer-pre-total {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
          }
          .end-footer {
            display: flex;
            gap: 10px;
  
            &.space {
              justify-content: space-between;
            }
  
            &.end {
              justify-content: flex-end;
            }
  
            .danger {
              color: var(--states-error);
            }
          }
          .items-title {
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-blue);
          }
          .negative {
            color: var(--states-error);
          }
          .pagination-position {
            width: 100%;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;

            .actions {
              display: flex;
              gap: 10px;
              align-items: center;
            }
          }
        }
      }
    }
}
</style>